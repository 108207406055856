// These helpers are calling this template's own server-side routes
// so, they are not directly calling Marketplace API or Integration API.
// You can find these api endpoints from 'server/api/...' directory

import appSettings from '../config/settings';
import { types as sdkTypes, transit } from './sdkLoader';
import Decimal from 'decimal.js';

export const apiBaseUrl = () => {
  const port = process.env.REACT_APP_DEV_API_SERVER_PORT;
  const useDevApiServer = process.env.NODE_ENV === 'development' && !!port;

  // In development, the dev API server is running in a different port
  if (useDevApiServer) {
    return `http://localhost:${port}`;
  }

  // Otherwise, use the same domain and port as the frontend
  return typeof window !== 'undefined' && `${window.location.origin}`;
};

// Application type handlers for JS SDK.
//
// NOTE: keep in sync with `typeHandlers` in `server/api-util/sdk.js`
export const typeHandlers = [
  // Use Decimal type instead of SDK's BigDecimal.
  {
    type: sdkTypes.BigDecimal,
    customType: Decimal,
    writer: v => new sdkTypes.BigDecimal(v.toString()),
    reader: v => new Decimal(v.value),
  },
];

const serialize = data => {
  return transit.write(data, { typeHandlers, verbose: appSettings.sdk.transitVerbose });
};

const deserialize = str => {
  return transit.read(str, { typeHandlers });
};

const post = (path, body) => {
  const url = `${apiBaseUrl()}${path}`;
  const options = {
    method: 'POST',
    credentials: 'include',
    headers: {
      'Content-Type': 'application/transit+json',
    },
    body: serialize(body),
  };
  return window.fetch(url, options).then(res => {
    const contentTypeHeader = res.headers.get('Content-Type');
    const contentType = contentTypeHeader ? contentTypeHeader.split(';')[0] : null;

    if (res.status >= 400) {
      return res.json().then(data => {
        let e = new Error();
        e = Object.assign(e, data);

        throw e;
      });
    }
    if (contentType === 'application/transit+json') {
      return res.text().then(deserialize);
    } else if (contentType === 'application/json') {
      return res.json();
    }
    return res.text();
  });
};

// Fetch transaction line items from the local API endpoint.
//
// See `server/api/transaction-line-items.js` to see what data should
// be sent in the body.
export const transactionLineItems = body => {
  return post('/api/transaction-line-items', body);
};

// Initiate a privileged transaction.
//
// With privileged transitions, the transactions need to be created
// from the backend. This endpoint enables sending the order data to
// the local backend, and passing that to the Marketplace API.
//
// See `server/api/initiate-privileged.js` to see what data should be
// sent in the body.
export const initiatePrivileged = body => {
  return post('/api/initiate-privileged', body);
};

export const updateStock = body => {
  return post('/api/update-stock', body);
};

// Transition a transaction with a privileged transition.
//
// This is similar to the `initiatePrivileged` above. It will use the
// backend for the transition. The backend endpoint will add the
// payment line items to the transition params.
//
// See `server/api/transition-privileged.js` to see what data should
// be sent in the body.
export const transitionPrivileged = body => {
  return post('/api/transition-privileged', body);
};

// Create user with identity provider (e.g. Facebook or Google)
//
// If loginWithIdp api call fails and user can't authenticate to Marketplace API with idp
// we will show option to create a new user with idp.
// For that user needs to confirm data fetched from the idp.
// After the confirmation, this endpoint is called to create a new user with confirmed data.
//
// See `server/api/auth/createUserWithIdp.js` to see what data should
// be sent in the body.
export const createUserWithIdp = body => {
  return post('/api/auth/create-user-with-idp', body);
};

export const onStrapiSchoolData = body => {
  return post('/api/onStrapiSchoolData', body);
}
export const createStrapiFreeLessonsData = body => {
  return post('/api/createStrapiFreeLessonsData', body);
};

export const getStrapiFreeLessonsData = body => {
  return post('/api/getStrapiFreeLessonsData', body);
}

export const updateStrapiFreeLessonsData = body => {
  return post('/api/updateStrapiFreeLessonsData', body);
}

export const onUpdateStrapiSchool = body => {
  return post('/api/updateStrapiSchoolData', body);
}

export const updateMetaDataApi = body => {
  return post('/api/updateMetaDataApi', body);
}

export const onJoinGuestEmail = body => {
  return post('/api/joinGuestEmail', body);
}

// stripe create product apis 
export const onStripeCreateProduct = body => {
  return post('/api/stripe-create-product', body);
}

// ========== stripe subscription apis ============ 
export const onCreateSubscription = (body) => {
  return post('/api/stripe-create-subscription', body)
}

export const onGetUserSubscriptions = (body) => {
  return post('/api/stripe-get-user-subscriptions', body)
}

export const onSearchSubscriptions = (body) => {
  return post('/api/stripe-search-subscription', body)
}

export const updateReviewsUserAndListing = (body) => {
  return post('/api/updateReviewsUserAndListing', body)
}

export const onGetSubscriptionInvoices = (body) => {
  return post('/api/getSubscriptionInvoices', body)
}

export const onCancelStripeSubscription = (body) => {
  return post('/api/cancel-stripe-subscription', body)
};

// userPackages CRUD 
export const onGetUserPackages = (body) => {
  return post('/api/get-user-packages', body)
}
export const onCreateUserPackages = (body) => {
  return post('/api/create-user-packages', body)
}
export const onUpdateUserPackages = (body) => {
  return post('/api/update-user-packages', body)
}
export const onDeleteUserPackages = (body) => {
  return post('/api/delete-user-packages', body)
}
export const onDeclineUserPackages = (body) => {
  return post('/api/decline-user-packages', body)
}

// session transaction  CRUD 
export const onGetSessionTransaction = (body) => {
  return post('/api/get-session-transaction', body)
}
export const onCreateSessionTransaction = (body) => {
  return post('/api/create-session-transaction', body)
}
export const onUpdateSessionTransaction = (body) => {
  return post('/api/update-session-transaction', body)
}
export const onDeleteSessionTransaction = (body) => {
  return post('/api/delete-session-transaction', body)
}

export const onCreateAvalabityException = (body) => {
  return post('/api/create-avalability-exception', body)
}

//notification
export const notifyUser = body => {
  return post('/api/notifyUsers', body);
};
// chat CRUD 
export const onGetStrapiChat = (body) => {
  return post('/api/get-strapi-chat', body)
}
export const onCreateStrapiChat = (body) => {
  return post('/api/create-strapi-chat', body)
}
export const onUpdateStrapiChat = (body) => {
  return post('/api/update-strapi-chat', body)
}
export const onDeleteStrapiChat = (body) => {
  return post('/api/delete-strapi-chat', body)
}

// chatList CRUD 

export const onGetStrapiChatList = (body) => {
  return post('/api/get-strapi-chat-list', body)
}
export const onCreateStrapiChatList = (body) => {
  return post('/api/create-strapi-chat-list', body)
}
export const onUpdateStrapiChatList = (body) => {
  return post('/api/update-strapi-chat-list', body)
}
export const onDeleteStrapiChatList = (body) => {
  return post('/api/delete-strapi-chat-list', body)
}

export const onSessionCancelMailUser = (body) => {
  return post('/api/sessionCancelMailUser', body);
}

// additional questions api strapi 

export const onCreateAdditionalQuestion = body => {
  return post('/api/create-additional-question', body);
}

export const onUpdateAdditionalQuestion = body => {
  return post('/api/update-additional-question', body);
}

export const onDeleteAdditionalQuestions = body => {
  return post('/api/delete-additional-question', body);
}

export const onGetAdditionalQuestions = body => {
  return post('/api/get-additional-question', body);
}

// delete session api strapi 
export const onCreateDeletedSession = body => {
  return post('/api/createDeletedSession', body);
}

// <<<<<<<<<<< notification create >>>>>>>>>>
export const onCreateNotification = body => {
  return post('/api/createNotification', body);
}

export const onCreateMultipleNotification = (body) => {
  return post('/api/createMultipleNotification', body);
}

export const onUserDeleteAccount = body => {
  return post('/api/userDeleteAccount', body);
};


// instructors create 
export const onCreateSchoolInstructors = body => {
  return post('/api/createSchoolInstructors', body);
};

export const onGetSchoolInstructors = body => {
  return post('/api/getSchoolInstructors', body);
};

export const onCheckAndUpdateSchoolInstructors = body => {
  return post('/api/checkAndUpdateSchoolInstructors', body);
};

export const onCreateSessionInstructors = body => {
  return post('/api/createSessionInstructors', body);
};

export const onGetSessionInstructors = body => {
  return post('/api/getSessionInstructors', body);
};

export const onUpdateListingIntegartion = body => {
  return post('/api/updateListing', body);
};

export const onBulkMessage = body => {
  return post('/api/bulkMessage', body);
};


// <<<<<<<<<<<<<<<<<<<<<< strapi generic apis  body =={ API_TYPE,  data} >>>>>>>>>>>>>>>>>>>>>
export const onGetApiCollecton = body => {
  return post('/api/getApiCollecton', body);
};
export const onCreateApiCollecton = body => {
  return post('/api/createApiCollecton', body);
};
export const onUpdateApiCollecton = body => {
  return post('/api/updateApiCollecton', body);
};
export const onDeleteApiCollecton = body => {
  return post('/api/deleteApiCollecton', body);
};
export const onFindApiCollecton = body => {
  return post('/api/findApiCollecton', body);
};