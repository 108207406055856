import moment from 'moment';
import uuid4 from 'uuid4';
import { addMarketplaceEntities } from '../../ducks/marketplaceData.duck';
import { fetchCurrentUser } from '../../ducks/user.duck';
import { types as sdkTypes, createImageVariantConfig } from '../../util/sdkLoader';
import { denormalisedResponseEntities, getUpdateQuestionsInUserProfile } from '../../util/data';
import { storableError } from '../../util/errors';
import { fetchProperTimeSlots } from '../SchoolDashBoardPage/SchoolDashBoardPage.duck';
import { LESSON_TYPE, NOTIFICATION_SCHOOL, SCHOOL_TYPE, SESSION_TYPE } from '../../util/types';
import { parse } from '../../util/urlHelpers';
import {
  onCreateSessionTransaction,
  onCreateAvalabityException,
  onUpdateUserPackages,
  onGetUserPackages,
  onGetStrapiChatList,
  onUpdateStrapiChatList,
  onCreateStrapiChatList,
  onCreateStrapiChat,
  onCreateNotification
} from '../../util/api';
import { updateProfile } from '../ProfileSettingsPage/ProfileSettingsPage.duck';

const { UUID } = sdkTypes;

const REACT_APP_MARKETPLACE_ROOT_URL = process.env.REACT_APP_MARKETPLACE_ROOT_URL;
const RESULT_PAGE_SIZE = 30;
// ================ Action types ================ //

export const SET_INITIAL_STATE = 'app/ProfilePage/SET_INITIAL_STATE';

export const SHOW_USER_REQUEST = 'app/ProfilePage/SHOW_USER_REQUEST';
export const SHOW_USER_SUCCESS = 'app/ProfilePage/SHOW_USER_SUCCESS';
export const SHOW_USER_ERROR = 'app/ProfilePage/SHOW_USER_ERROR';

export const QUERY_LISTINGS_REQUEST = 'app/ProfilePage/QUERY_LISTINGS_REQUEST';
export const QUERY_LISTINGS_SUCCESS = 'app/ProfilePage/QUERY_LISTINGS_SUCCESS';
export const QUERY_LISTINGS_ERROR = 'app/ProfilePage/QUERY_LISTINGS_ERROR';

export const QUERY_REVIEWS_REQUEST = 'app/ProfilePage/QUERY_REVIEWS_REQUEST';
export const QUERY_REVIEWS_SUCCESS = 'app/ProfilePage/QUERY_REVIEWS_SUCCESS';
export const QUERY_REVIEWS_ERROR = 'app/ProfilePage/QUERY_REVIEWS_ERROR';

export const JOIN_SESSION_REQUEST = 'app/ProfilePage/JOIN_SESSION_REQUEST';

export const GET_USER_PACKAGES = 'app/ProfilePage/GET_USER_PACKAGES'

export const PROFILE_PAGE_LOADER = 'app/ProfilePage/PROFILE_PAGE_LOADER';

export const SEND_MESSAGE_REQUEST = 'app/ProfilePage/SEND_MESSAGE_REQUEST';

// ================ Reducer ================ //

const initialState = {
  userId: null,
  userListingRefs: [],
  userShowError: null,
  queryListingsError: null,
  reviews: [],
  queryReviewsError: null,
  allTimeSlots: [],
  onJoinSessionRequest: false,
  profilePageLoader: true,
  userPackages: [],
  sendMessageRequest: false,
};

export default function profilePageReducer(state = initialState, action = {}) {
  const { type, payload } = action;
  switch (type) {
    case SET_INITIAL_STATE:
      return { ...initialState };
    case SHOW_USER_REQUEST:
      return { ...state, userShowError: null, userId: payload.userId };
    case SHOW_USER_SUCCESS:
      return { ...state };
    case SHOW_USER_ERROR:
      return { ...state, userShowError: payload, };

    case QUERY_LISTINGS_REQUEST:
      return {
        ...state,
        // Empty listings only when user id changes
        userListingRefs: payload.userId === state.userId ? state.userListingRefs : [],
        queryListingsError: null,
      };
    case QUERY_LISTINGS_SUCCESS:
      return { ...state, userListingRefs: payload.listingRefs, allTimeSlots: payload.allTimeSlots, pagination: payload.meta };
    case QUERY_LISTINGS_ERROR:
      return { ...state, userListingRefs: [], queryListingsError: payload };

    case QUERY_REVIEWS_REQUEST:
      return { ...state, queryReviewsError: null };
    case QUERY_REVIEWS_SUCCESS:
      return { ...state, reviews: payload };
    case QUERY_REVIEWS_ERROR:
      return { ...state, reviews: [], queryReviewsError: payload };

    case JOIN_SESSION_REQUEST:
      return { ...state, onJoinSessionRequest: payload }

    case GET_USER_PACKAGES:
      return { ...state, userPackages: payload }

    case PROFILE_PAGE_LOADER:
      return { ...state, profilePageLoader: payload }

    case SEND_MESSAGE_REQUEST:
      return { ...state, sendMessageRequest: payload }

    default:
      return state;
  }
}

// ================ Action creators ================ //

export const setInitialState = () => ({
  type: SET_INITIAL_STATE,
});

export const showUserRequest = userId => ({
  type: SHOW_USER_REQUEST,
  payload: { userId },
});

export const showUserSuccess = () => ({
  type: SHOW_USER_SUCCESS,
});

export const showUserError = e => ({
  type: SHOW_USER_ERROR,
  error: true,
  payload: e,
});

export const queryListingsRequest = userId => ({
  type: QUERY_LISTINGS_REQUEST,
  payload: { userId },
});

export const queryListingsSuccess = (data) => ({
  type: QUERY_LISTINGS_SUCCESS,
  payload: data,
});

export const queryListingsError = e => ({
  type: QUERY_LISTINGS_ERROR,
  error: true,
  payload: e,
});

export const queryReviewsRequest = () => ({
  type: QUERY_REVIEWS_REQUEST,
});

export const queryReviewsSuccess = reviews => ({
  type: QUERY_REVIEWS_SUCCESS,
  payload: reviews,
});

export const queryReviewsError = e => ({
  type: QUERY_REVIEWS_ERROR,
  error: true,
  payload: e,
});

export const joinSessionRequest = (data) => ({
  type: JOIN_SESSION_REQUEST,
  payload: data
})

export const profilePageLoader = (data) => ({
  type: PROFILE_PAGE_LOADER,
  payload: data
});

export const getUserPackages = data => ({
  type: GET_USER_PACKAGES,
  payload: data,
});

export const sendMessageRequest = data => ({
  type: SEND_MESSAGE_REQUEST,
  payload: data,
});

// ================ Thunks ================ //

export const sendMessageSchool = (params) => async (dispatch, getState, sdk) => {
  try {
    dispatch(sendMessageRequest(true));
    const { chatListParams, chatParams } = params;
    const { users1, users2 } = chatListParams;
    const searchFilter = `filters[$or][0][users][$eq]=${users1}&filters[$or][1][users][$eq]=${users2}`;
    let chatId = "";
    console.log(chatParams, '&&& &&& => chatParams');
    console.log(chatListParams, '&&& &&& => chatListParams');
    return onGetStrapiChatList({ searchFilter })
      .then(async (response) => {
        chatId = response.data && response.data.length ? response.data[0].attributes.chatId : uuid4();

        if (response.data.length == 0) {
          await onCreateStrapiChatList({ ...chatListParams, chatId, users: users1 })
        } else if (response.data.length) {
          const { id } = response.data[0];
          await onUpdateStrapiChatList({ id, isDeleted: false })
        }

        return onCreateStrapiChat({ ...chatParams, chatId });
      })
      .then(async (response) => {

        try {
          const { receiverId, message, senderName } = chatParams;
          await onCreateNotification({
            external_id: [receiverId],
            contentTitle: message,
            heading: senderName,
            web_url: REACT_APP_MARKETPLACE_ROOT_URL + "/chat/" + chatId
          })
        } catch (e) {
          console.log('&&& onCreateNotification Error &&& => sendMessageSchool 202');
        }

        dispatch(sendMessageRequest(false));
        return chatId;
      })
      .catch((e) => {
        dispatch(sendMessageRequest(false));
        return storableError(e);
      })

  } catch (e) {
    dispatch(sendMessageRequest(false));
    return storableError(e);
  }
}


export const joinSessionTransaction = (params) => async (dispatch, getState, sdk) => {
  try {
    dispatch(joinSessionRequest(true));
    const { sessionTransactionJson, updatedUserPackagesData, availabiltyException, sessionName = "" } = params;
    const { id, start } = availabiltyException;
    const currentSlotSeats = await sdk.timeslots.query({
      listingId: new UUID(id),
      start: moment().toDate(),
      end: moment().add(90, "days").toDate()
    });

    const slotData = currentSlotSeats.data.data.find((st) => moment(st.attributes.start).locale('en').format("DD-MM-YYYY") == moment(start).locale('en').format("DD-MM-YYYY"));
    const { seats } = slotData.attributes;
    const newAvailabiltyException = { ...slotData.attributes, id, seats: seats - 1 };

    return onCreateAvalabityException(newAvailabiltyException)
      .then((response) => {
        return onCreateSessionTransaction({ sessionTransaction: [sessionTransactionJson] });
      })
      .then((response) => {
        return onUpdateUserPackages(updatedUserPackagesData);
      })
      .then(async response => {
        try {
          const currentUser = getState().user.currentUser;
          // added the latest question answer in userProfile   
          const combinedResult = getUpdateQuestionsInUserProfile(sessionTransactionJson, currentUser);
          dispatch(updateProfile({
            userParams: {
              publicData: {
                customQuestion: combinedResult
              },
            }
          }));

          // send one signal notification to school 
          const { authorId = "11", userName, isGuest, guestFirstName = "", guestLastName = "", listingId, unixDate } = sessionTransactionJson || {};
          const joinedUser = isGuest == "you" ? userName : guestFirstName + " " + guestLastName
          await onCreateNotification({
            external_id: [authorId],
            contentTitle: joinedUser + " se ha apuntado a " + sessionName,
            heading: "Alumno apuntado",
            web_url: REACT_APP_MARKETPLACE_ROOT_URL + "/list-dashboard/" + listingId + "?calendarDate=" + moment(unixDate).format("YYYY-MM-DD")
          })
        } catch (e) {
          console.log('&&& onCreateNotification Error &&& => onFreeLessonIntiateOrder 351', e);
        }

        return { status: 200 }
      })
      .catch((e) => {
        dispatch(joinSessionRequest(false));
        return storableError(e);
      })

  } catch (e) {
    console.log(e, '&&& &&& => e');
    dispatch(joinSessionRequest(false));
    return storableError(e);
  }
}

// listings must be array and having length 
export const getTimeSlots = async (listings = [], sdk) => {
  let timeSlot = [];
  for (let i = 0; i < listings.length; i++) {
    const { id, attributes } = listings[i] || {};
    const startDate = attributes?.publicData?.startDate && moment(attributes?.publicData?.startDate).unix() > moment().unix();

    const allTimeSlots = await sdk.timeslots.query({
      listingId: id,
      start: startDate ? moment(attributes?.publicData?.startDate).startOf('day').toDate() : moment().startOf('day').toDate(),
      end: moment().add(90, "day").startOf('day').toDate(),
    });

    if (allTimeSlots && allTimeSlots.data.data && allTimeSlots.data.data.length) {
      const newTimeSlotValues = fetchProperTimeSlots({ timeSlotsIs: allTimeSlots.data.data, listing: listings[i] });
      timeSlot = [...timeSlot, ...newTimeSlotValues]
    }

  }
  const sortTimeSlots = timeSlot && timeSlot.length ? timeSlot.sort((on, to) => moment(on.attributes.start).unix() - moment(to.attributes.start).unix()) : [];
  return sortTimeSlots;
}

export const queryUserListings = (userId, config, lessonPagination) => (dispatch, getState, sdk) => {
  dispatch(queryListingsRequest(userId));

  const {
    aspectWidth = 1,
    aspectHeight = 1,
    variantPrefix = 'listing-card',
  } = config.layout.listingImage;
  const aspectRatio = aspectHeight / aspectWidth;
  let listingRefs = [];

  return sdk.listings
    .query({
      author_id: userId,
      pub_learningType: SCHOOL_TYPE,
      include: ['author', 'images'],
      'fields.image': [`variants.${variantPrefix}`, `variants.${variantPrefix}-2x`],
      ...createImageVariantConfig(`${variantPrefix}`, 400, aspectRatio),
      ...createImageVariantConfig(`${variantPrefix}-2x`, 800, aspectRatio),
    }).then((response) => {
      dispatch(addMarketplaceEntities(response));
      if (response.data.data.length) {
        listingRefs = [{ id: response.data.data[0].id, type: response.data.data[0].type }]
      }
      return sdk.listings
        .query({
          author_id: userId,
          pub_learningType: LESSON_TYPE,
          sort: "-pub_sortPriority",
          include: ['author', 'images'],
          'fields.image': [`variants.${variantPrefix}`, `variants.${variantPrefix}-2x`],
          ...createImageVariantConfig(`${variantPrefix}`, 400, aspectRatio),
          ...createImageVariantConfig(`${variantPrefix}-2x`, 800, aspectRatio),
          ...lessonPagination
        })
    })
    .then(async (response) => {
      // Pick only the id and type properties from the response listings

      const listingRefsLessons = response.data.data.map(({ id, type }) => ({ id, type }));
      listingRefs = [...listingRefs, ...listingRefsLessons]
      let allTimeSlots = [];
      if (response?.data?.data && response.data.data.length) {
        const sessionListings = await sdk.listings.query({
          author_id: userId,
          pub_learningType: SESSION_TYPE,
          pub_sessionDatePassed: (moment().unix() + ",")
        });
        const filterListing = sessionListings && sessionListings.data.data.filter((st) => st.attributes.publicData.learningType == SESSION_TYPE);
        allTimeSlots = filterListing && filterListing.length ? await getTimeSlots(filterListing, sdk) : [];
      }
      dispatch(addMarketplaceEntities(response));
      dispatch(queryListingsSuccess({ listingRefs, allTimeSlots, meta: response.data.meta }));
      return response;
    })
    .catch(e => dispatch(queryListingsError(storableError(e))));
};

export const queryUserReviews = (userId) => (dispatch, getState, sdk) => {
  sdk.reviews
    .query({
      subject_id: userId,
      state: 'public',
      include: ['author', 'author.profileImage'],
      'fields.image': ['variants.square-small', 'variants.square-small2x'],
    })
    .then(response => {
      const reviews = denormalisedResponseEntities(response);
      dispatch(queryReviewsSuccess(reviews));
    })
    .catch(e => dispatch(queryReviewsError(e)));
};

export const queryUserPackages = (userId) => async (dispatch, getState, sdk) => {
  const searchFilter = `pagination[page]=${1}&pagination[pageSize]=${100}&filters[userId][$eq]=${userId}&filters[quantity][$gt]=0`;
  return onGetUserPackages({ searchFilter }).then((response) => {
    dispatch(getUserPackages(response.data || []));
  })
    .catch((e) => console.log(e, '&&& &&& => e'));
};

export const showUser = userId => (dispatch, getState, sdk) => {
  dispatch(showUserRequest(userId));
  return sdk.users
    .show({
      id: userId,
      include: ['profileImage'],
      'fields.image': ['variants.square-small', 'variants.square-small2x'],
    })
    .then(response => {
      dispatch(addMarketplaceEntities(response));
      dispatch(showUserSuccess());
      return response;
    })
    .catch(e => dispatch(showUserError(storableError(e))));
};

export const loadData = (params, search, config) => (dispatch, getState, sdk) => {
  const userId = new UUID(params.id);

  const queryParams = parse(search);
  const page = queryParams.page || 1;

  const lessonPagination = {
    page,
    perPage: RESULT_PAGE_SIZE,
  }

  // Clear state so that previously loaded data is not visible
  // in case this page load fails
  dispatch(setInitialState());
  dispatch(profilePageLoader(true));
  return Promise.all([
    dispatch(fetchCurrentUser()),
    dispatch(showUser(userId)),
    dispatch(queryUserListings(userId, config, lessonPagination)),
    dispatch(queryUserReviews(userId)),
  ]).then(res => {
    const currentUser = getState().user?.currentUser;
    const userId = currentUser?.id?.uuid;
    if (userId) {
      dispatch(queryUserPackages(userId));
    }
    dispatch(profilePageLoader(false));
  }).catch((e) => {
    dispatch(profilePageLoader(false));
  })
};


export const fetchUserPackages = (transactions = []) => {
  const filteredTransactions = transactions.filter((st) => st.attributes?.metadata?.userPackages && st.attributes?.metadata?.userPackages.length);
  if (filteredTransactions && filteredTransactions.length) {
    let allPackages = [];
    for (let i = 0; i < filteredTransactions.length; i++) {
      const eachPackage = filteredTransactions[i].attributes.metadata.userPackages;
      allPackages = [...allPackages, ...eachPackage];
    }
    return allPackages;
  } else {
    return [];
  }
}

export const fetchProviders = (transactions) => {
  let allProviders = [];
  if (transactions && transactions.length) {
    for (let i = 0; i < transactions.length; i++) {
      const element = transactions[i].provider;
      if (element && allProviders.findIndex((st) => st.id?.uuid == element.id?.uuid) >= 0) {
      } else if (element?.id?.uuid) {
        allProviders = [...allProviders, element];
      }
    }
  }
  return allProviders;
}


export const fetchMainTransactionAndPackages = (transactions, listingId) => {
  const userPackages = transactions && transactions.length ? fetchUserPackages(transactions) : [];
  const getAParticularTransaction = userPackages && userPackages.length ? userPackages.filter((st) => st.quantity > 0 && st.quantity <= st.totalQuantity && listingId == st.listingId) : false;
  const JoinButton = (getAParticularTransaction && getAParticularTransaction.length) ? getAParticularTransaction[0] : false;
  const currentTransactionPackage = (JoinButton && transactions && transactions.length) ? transactions.filter((st) => st.id.uuid == JoinButton.transactionId) : false;

  const currentTransactionUserPackages = (currentTransactionPackage && currentTransactionPackage.length) ? currentTransactionPackage[0].attributes?.metadata?.userPackages : false;

  let userSessionsJoined = [];
  if (userPackages && userPackages.length) {
    for (let i = 0; i < userPackages.length; i++) {
      const { joinedSessions = [] } = userPackages[i];
      userSessionsJoined = [...userSessionsJoined, ...joinedSessions]
    }
  }

  return { JoinButton: JoinButton, currentTransactionUserPackages: currentTransactionUserPackages, userSessionsJoined: userSessionsJoined }
}

// const { start, end, startTime, endTime, lessonName, additionalQuestions, location, joiningSessionGuest = [] } = st.attributes?.protectedData;
// const joinedSessionBy = joiningSessionGuest && joiningSessionGuest.length ? joiningSessionGuest[0] : false;
// pending done 


const getAllDaysTimeSlots = (startDateUnix, endDateUnix, daysOfWeek) => {
  // Convert Unix timestamps to Moment objects
  const currentDate = moment(startDateUnix * 1000);
  const endDate = moment(endDateUnix * 1000);
  const matchingDays = [];
  const alldays = daysOfWeek.map((st) => st.day);

  while (currentDate.isSameOrBefore(endDate, 'day')) {
    // Check if the day of the week matches any of the specified days
    if (alldays.includes(currentDate.format('dddd'))) {
      const startTimeIndex = daysOfWeek.findIndex((st) => st.day == currentDate.format('dddd'));
      const currentDateIs = moment().format("DD-MM-YYYY");
      if (currentDateIs == currentDate.format("DD-MM-YYYY") && startTimeIndex >= 0) {
        (moment(new Date(currentDateIs + " " + alldays[startTimeIndex])).unix()) > currentDate.unix() ? matchingDays.push({ currentDateIs: currentDate.unix(), day: currentDate.format('dddd') }) : null;
      } else {
        matchingDays.push({ currentDateIs: currentDate.unix(), day: currentDate.format('dddd') });
      }
    }
    currentDate.add(1, 'day');
  }
  return matchingDays;
};


export const getSubscriptionArray = (subscriptionTransaction, tab) => {
  let allSubscriptionSlots = [];
  const filteredSubscriptionTransaction = subscriptionTransaction && subscriptionTransaction.filter(item => item.attributes?.metadata?.userSubscriptionData && item.attributes?.metadata?.userSubscriptionData.id);

  if (filteredSubscriptionTransaction && filteredSubscriptionTransaction.length) {
    for (let i = 0; i < filteredSubscriptionTransaction.length; i++) {
      const element = filteredSubscriptionTransaction[i];
      const { current_period_end, current_period_start } = filteredSubscriptionTransaction[i].attributes?.metadata?.userSubscriptionData;
      const { publicData, title } = filteredSubscriptionTransaction[i].listing?.attributes;
      const days = publicData?.dayAvailability || [];

      if (tab == "pending") {
        const pendingStartDate = current_period_start > moment().unix() ? current_period_start : moment().unix()
        const allPendingSlots = current_period_end > moment().unix() ? getAllDaysTimeSlots(pendingStartDate, current_period_end, days) : [];
        allPendingSlots.forEach((st) => {
          const findIndexDay = publicData?.dayAvailability.findIndex((item) => item.day == st.day)
          const protectedDataNew = findIndexDay >= 0 ? {
            lessonName: title, type: "time-slot/time", start: st.currentDateIs, end: st.currentDateIs, parentTransactionId: element.id.uuid,
            ...publicData?.dayAvailability[findIndexDay],
            ...publicData
          } : { lessonName: title, type: "time-slot/time", start: st.currentDateIs, end: st.currentDateIs, ...publicData }

          const newElement = {
            attributes: { ...element.attributes, protectedData: { ...protectedDataNew, ...element.attributes.protectedData } },
            customer: element.customer,
            id: element.id,
            provider: element.provider,
            reviews: element.reviews,
            type: element.type,
            listing: element.listing,
            booking: element.booking
          }

          allSubscriptionSlots.push(newElement)
        })
      }

      if (tab == "done") {
        const doneEndDate = current_period_end > moment().unix() ? moment().unix() : current_period_end;
        const allDoneSlots = current_period_start < moment().unix() ? getAllDaysTimeSlots(current_period_start, doneEndDate, days) : [];
        allDoneSlots.forEach((st) => {
          const findIndexDay = publicData?.dayAvailability.findIndex((item) => item.day == st.day)
          const protectedDataNew = findIndexDay >= 0 ? {
            lessonName: title, type: "time-slot/time", start: st.currentDateIs, end: st.currentDateIs, parentTransactionId: element.id.uuid,
            ...publicData?.dayAvailability[findIndexDay],
            ...publicData
          } : { lessonName: title, type: "time-slot/time", start: st.currentDateIs, end: st.currentDateIs, ...publicData }

          const newElement = {
            attributes: { ...element.attributes, protectedData: { ...protectedDataNew, ...element.attributes.protectedData } },
            customer: element.customer,
            id: element.id,
            provider: element.provider,
            reviews: element.reviews,
            type: element.type,
            listing: element.listing,
            booking: element.booking
          }

          allSubscriptionSlots.push(newElement)
        })

      }


    }
  }

  return allSubscriptionSlots;
}  
