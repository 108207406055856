/////////////////////////////////////////////////////////
// Configurations related to listing.                  //
// Main configuration here is the extended data config //
/////////////////////////////////////////////////////////

// NOTE: if you want to change the structure of the data,
// you should also check src/util/configHelpers.js
// some validation is added there.

/**
 * Configuration options for listing fields (custom extended data fields):
 * - key:                           Unique key for the extended data field.
 * - scope (optional):              Scope of the extended data can be either 'public' or 'private'.
 *                                  Default value: 'public'.
 *                                  Note: listing doesn't support 'protected' scope atm.
 * - includeForListingTypes:        An array of listing types, for which the extended
 *   (optional)                     data is relevant and should be added.
 * - schemaType (optional):         Schema for this extended data field.
 *                                  This is relevant when rendering components and querying listings.
 *                                  Possible values: 'enum', 'multi-enum', 'text', 'long', 'boolean'.
 * - enumOptions (optional):        Options shown for 'enum' and 'multi-enum' extended data.
 *                                  These are used to render options for inputs and filters on
 *                                  EditListingPage, ListingPage, and SearchPage.
 * - filterConfig:                  Filter configuration for listings query.
 *    - indexForSearch (optional):    If set as true, it is assumed that the extended data key has
 *                                    search index in place. I.e. the key can be used to filter
 *                                    listing queries (then scope needs to be 'public').
 *                                    Note: Flex CLI can be used to set search index for the key:
 *                                    https://www.sharetribe.com/docs/references/extended-data/#search-schema
 *                                    Read more about filtering listings with public data keys from API Reference:
 *                                    https://www.sharetribe.com/api-reference/marketplace.html#extended-data-filtering
 *                                    Default value: false,
 *   - filterType:                    Sometimes a single schemaType can be rendered with different filter components.
 *                                    For 'enum' schema, filterType can be 'SelectSingleFilter' or 'SelectMultipleFilter'
 *   - label:                         Label for the filter, if the field can be used as query filter
 *   - searchMode (optional):         Search mode for indexed data with multi-enum schema.
 *                                    Possible values: 'has_all' or 'has_any'.
 *   - group:                         SearchPageWithMap has grouped filters. Possible values: 'primary' or 'secondary'.
 * - showConfig:                    Configuration for rendering listing. (How the field should be shown.)
 *   - label:                         Label for the saved data.
 *   - isDetail                       Can be used to hide detail row (of type enum, boolean, or long) from listing page.
 *                                    Default value: true,
 * - saveConfig:                    Configuration for adding and modifying extended data fields.
 *   - label:                         Label for the input field.
 *   - placeholderMessage (optional): Default message for user input.
 *   - isRequired (optional):         Is the field required for providers to fill
 *   - requiredMessage (optional):    Message for those fields, which are mandatory.
 */
export const listingFields = [
  {
    key: 'learningType',
    scope: 'public',
    schemaType: 'enum',
    enumOptions: [
      { option: 'SCHOOL', label: 'SCHOOL' },
      { option: 'LESSON', label: 'LESSON' },
    ],
    filterConfig: {
      indexForSearch: true,
      filterType: 'SelectSingleFilter',
      label: 'Category',
      group: 'primary',
    },
    showConfig: {
      label: 'Category',
      isDetail: true,
    },
    saveConfig: {
      label: 'Category',
      placeholderMessage: 'Select an option…',
      isRequired: true,
      requiredMessage: 'You need to select a category.',
    },
  },

  // // An example of how to use transaction type specific custom fields and private data.
  // {
  //   key: 'note',
  //   scope: 'public',
  //   includeForListingTypes: ['product-selling'],
  //   schemaType: 'text',
  //   showConfig: {
  //     label: 'Extra notes',
  //   },
  //   saveConfig: {
  //     label: 'Extra notes',
  //     placeholderMessage: 'Some public extra note about this bike...',
  //   },
  // },
  // {
  //   key: 'privatenote',
  //   scope: 'private',
  //   includeForListingTypes: ['daily-booking'],
  //   schemaType: 'text',
  //   saveConfig: {
  //     label: 'Private notes',
  //     placeholderMessage: 'Some private note about this bike...',
  //   },
  // },
];

///////////////////////////////////////////////////////////////////////
// Configurations related to listing types and transaction processes //
///////////////////////////////////////////////////////////////////////

// A presets of supported listing configurations
//
// Note 1: With first iteration of hosted configs, we are unlikely to support
//         multiple listing types, even though this template has some
//         rudimentary support for it.
// Note 2: transaction type is part of listing type. It defines what transaction process and units
//         are used when transaction is created against a specific listing.

/**
 * Configuration options for listing experience:
 * - listingType:     Unique string. This will be saved to listing's public data on
 *                    EditListingWizard.
 * - label            Label for the listing type. Used as microcopy for options to select
 *                    listing type in EditListingWizard.
 * - transactionType  Set of configurations how this listing type will behave when transaction is
 *                    created.
 *   - process          Transaction process.
 *                      The process must match one of the processes that this client app can handle
 *                      (check src/util/transaction.js) and the process must also exists in correct
 *                      marketplace environment.
 *   - alias            Valid alias for the aforementioned process. This will be saved to listing's
 *                      public data as transctionProcessAlias and transaction is initiated with this.
 *   - unitType         Unit type is mainly used as pricing unit. This will be saved to
 *                      transaction's protected data.
 *                      Recommendation: don't use same unit types in completely different processes
 *                      ('item' sold should not be priced the same as 'item' booked).
 * - stockType        This is relevant only to listings with product-selling listing type.
 *                    If set to 'oneItem', stock management is not showed and the listing is
 *                    considered unique (stock = 1).
 *                    Possible values: 'oneItem' and 'multipleItems'.
 *                    Default: 'multipleItems'.
 */

export const listingTypes = [
  // {
  //   listingType: 'daily-booking',
  //   label: 'Daily booking',
  //   transactionType: {
  //     process: 'default-booking',
  //     alias: 'default-booking/release-1',
  //     unitType: 'day',
  //   },
  // },
  // // Here are some examples for other listingTypes
  // // TODO: SearchPage does not work well if both booking and product selling are used at the same time
  // {
  //   listingType: 'nightly-booking',
  //   label: 'Nightly booking',
  //   transactionType: {
  //     process: 'default-booking',
  //     alias: 'default-booking/release-1',
  //     unitType: 'night',
  //   },
  // },
  // {
  //   listingType: 'hourly-booking',
  //   label: 'Hourly booking',
  //   transactionType: {
  //     process: 'default-booking',
  //     alias: 'default-booking/release-1',
  //     unitType: 'hour',
  //   },
  // },
  {
    listingType: 'product-selling',
    label: 'Sell bicycles',
    transactionType: {
      process: 'default-purchase',
      alias: 'default-purchase/release-1',
      unitType: 'item',
    },
    stockType: 'multipleItems',
  },
];

// SearchPage can enforce listing query to only those listings with valid listingType
// However, it only works if you have set 'enum' type search schema for the public data fields
//   - listingType
//
//  Similar setup could be expanded to 2 other extended data fields:
//   - transactionProcessAlias
//   - unitType
//
// Read More:
// https://www.sharetribe.com/docs/how-to/manage-search-schemas-with-flex-cli/#adding-listing-search-schemas
export const enforceValidListingType = false;

export const joinSessionPeriodTypes= [{ value: "hour", key: "Horas" }, { value: "day", key: "Días" }, { value: "week", key: "Semanas" }];
export const joinSessionPeriodValidTypes=["hour","day","weeek"];

export const allCancelPeriodTypes =[{ value: "hour", key: "Horas" }, { value: "day", key: "Días" }, { value: "week", key: "Semanas" }];
export const cancelPeriodValidTypes=["hour","day","weeek"];

// <<<<<<<<<<<< school current User >>>>>>>>>>>>>>>>
const schoolCurrentUserSchema = {
  "email": "marryschool1@yopmail.com",
  "profile": {
    "firstName": "school marry",
    "lastName": "m",
    "displayName": "school marry m",
    "bio": "",
    "abbreviatedName": "sm",
    "publicData": {
      "schoolName": "international school", // name of that school 
      "isCashEnabled": true, // during checkout if school is chareged pay by cash only valid for LESSON || PACKS Not valid for COURSE 
      "city": "Washington",
      "followingArray": [
        {
          "id": "65f90b28-699a-4b0e-942b-5f35b9418f85",
          "value": "following"
        },
      ], // users follow that school 
      "followingArraySearch": [
        "65f90b28-699a-4b0e-942b-5f35b9418f85",
        "65840bcb-526a-4ee3-b1cd-aed92668cc21"
      ],
      "id": 14,

      "legalDocumentation": [
        {
          "title": "new",
          "text": "Lorem Ipsum is simply dummy text of the printing and typesetting industry.",

        },

      ], // we add in each lesson during crete a lesson 
      "location": {
        "address": "Manhattan, New York, United States",
        "origin": {
          "lat": 40.789624,
          "lng": -73.959894
        }
      },
      "phoneNumber": "9817098175",
      "question": [
        {
          "question": "whats your Age.",
          "questionType": "number"
        },
        {
          "question": "whats your size of shoes.",
          "questionType": "select"
        },
        {
          "question": "You joined before",
          "questionType": "boolean"
        }
      ], // we add in each session during session create

      "sports": [
        {
          "key": "buceo",
          "label": "Buceo",
          "value": "buceo"
        },],// school sports 

      "terms": [
        "tos-and-privacy"
      ],
      "userListingId": "6577d97f-8380-493c-9788-158804d8d865",// user school listing id 
      "userListingType": "SCHOOL",
      "userRating": 20,// total rating towards that user 
      "userTotalRating": 4 // number of rating towards that user 
    },
    "metadata": {},
    "privateData": {},
    "protectedData": {
      "terms": [
        "tos-and-privacy"
      ],
      "userListingType": "SCHOOL"
    },
  },
  "deleted": false,
  "banned": false,
  "stripeConnected": true,
  "stripePayoutsEnabled": false,
  "createdAt": "2024-12-12T03:48:23.331Z",
  "stripeChargesEnabled": false,
  "identityProviders": [],
  "pendingEmail": null,
  "emailVerified": true,
};

// <<<<<<<<<<<< PACK LESSON SCHEMA >>>>>>>>>>>>>>>>
const packLessonListingSchema = {
  "publicData": {
    // EditListingDetailsForm
    "transactionProcessAlias": "default-purchase/release-1",
    "listingType": "product-selling",
    "unitType": "item",

    "learningType": "LESSON", // as lesson type have two type SCHOOL == for school Profile we crete to show in search AND LESSON for purchase a lesson So we search as per according 
    "lessonCategory": "PACK",  // as lessonCategory have three type SINGLE && PACK( in which isShareable is included and lessonPacks and quantity ) && COURSE So we search as per according 
    "type": "CASH",

    "isShareable": true, // check lesson is shareable or not 
    "sport": [
      "golf"
    ], // for search we used sport select 
    "lessonPacks": [
      {
        "description": "Descripción del cursoDescripción del cursoDescripción del cursoDescripción del curso",
        "id": "66a08d5f-9bb2-4b9f-9fd9-e99680d1597c",
        "price": 2500,
        "publicData": {
          "cancelPeriodTime": "3",
          "cancelPeriodType": "hour",
          "cancelPolicy": "Política de cancelaciónPolítica de cancelaciónPolítica de cancelaciónPolítica de cancelación",
          "city": "Washington",
          "isShareable": false,
          "joinSessionPeriodType": "hour",
          "learningType": "LESSON",
          "legalDocumentation": [
            {
              "key": "new",
              "label": "new",
              "text": "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum."
            },
            {
              "key": "Rajat",
              "label": "Rajat",
              "text": "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum."
            }
          ],
          "lessonCategory": "SINGLE",
          "lessonPacks": [],
          "listingType": "product-selling",
          "location": {
            "address": null
          },
          "pickupEnabled": true,
          "quantity": 0,
          "shortDescription": "Descripción cortaDescripción cortaDescripción cortaDescripción corta",
          "sport": [
            "golf"
          ],
          "studentsJoinSession": "3",
          "transactionProcessAlias": "default-purchase/release-1",
          "type": "CASH",
          "unitType": "item"
        },
        "sport": [
          "golf"
        ],
        "title": "Golf Lesson Individual"
      }
    ],
    "quantity": "10",

    "location": {
      "address": null
    },

    // EditListingDetailsDescription
    "shortDescription": "Descripción de clase",
    "cancelPolicy": "Política de cancelaciónPolítica de cancelaciónPolítica de cancelación",

    "cancelPeriodTime": "2", // cancel session before that time when user joined 
    "cancelPeriodType": "hour",

    "studentsJoinSession": "2", // join lessons session before that time 
    "joinSessionPeriodType": "hour",

    "city": "Washington",
    "pickupEnabled": true,

    "sortPriority": 3, // search schema for sorting based on prority 
    "legalDocumentation": [
      {
        "key": "new",
        "label": "new",
        "text": "new"
      },
      {
        "key": "Rajat",
        "label": "Rajat",
        "text": "rajat"
      }
    ],  // shows at the time of purchase a lesson 
  }
}
// <<<<<<<<<<<< Simple LESSON SCHEMA >>>>>>>>>>>>>>>>
const simpleLessonListingSchema = {
  "publicData": {
    // EditListingDetailsForm
    "transactionProcessAlias": "default-purchase/release-1",
    "listingType": "product-selling",
    "unitType": "item",

    "learningType": "LESSON", // as lesson type have two type SCHOOL == for school Profile we crete to show in search AND LESSON for purchase a lesson So we search as per according 
    "lessonCategory": "SINGLE",  // as lessonCategory have three type SINGLE && PACK( in which isShareable is included and lessonPacks and quantity ) && COURSE So we search as per according 
    "type": "CASH",

    "isShareable": false, // check lesson is shareable or not 
    "sport": [
      "golf"
    ], // for search we used sport select 


    "location": {
      "address": null
    },

    // EditListingDetailsDescription
    "shortDescription": "Descripción de clase",
    "cancelPolicy": "Política de cancelaciónPolítica de cancelaciónPolítica de cancelación",

    "cancelPeriodTime": "2", // cancel session before that time when user joined 
    "cancelPeriodType": "hour",

    "studentsJoinSession": "2", // join lessons session before that time 
    "joinSessionPeriodType": "hour",

    "minCancelQty":"2", // minmum quantity user can join before 

    "city": "Washington",
    "pickupEnabled": true,

    "sortPriority": 3, // search schema for sorting based on prority 
    "legalDocumentation": [
      {
        "key": "new",
        "label": "new",
        "text": "new"
      },
      {
        "key": "Rajat",
        "label": "Rajat",
        "text": "rajat"
      }
    ],  // shows at the time of purchase a lesson 
  }
};
// <<<<<<<<<<<< Course LESSON SCHEMA >>>>>>>>>>>>>>>>
const courseLessonListingSchema = {
  "publicData": {
    // EditListingDetailsForm
    "transactionProcessAlias": "session-course/release-1",
    "listingType": "session-course",
    "unitType": "item",

    "learningType": "LESSON", // as lesson type have two type SCHOOL(for school Profile we crete to show in search) AND LESSON(for purchase a lesson So we search as per according)  AND SESSION(for sessions )
    "lessonCategory": "COURSE",  // as lessonCategory have three type SINGLE && PACK( in which isShareable is included and lessonPacks and quantity ) && COURSE So we search as per according 
    "type": "CASH",

    "sport": [
      "golf"
    ], // for search we used sport select 

    "startDate": "2024-01-01",
    "endDate": "2024-03-31",
    "openAccordion": "Thursday",
    "periodicity": "multiple", // periodicity is single and multiple
    "dayAvailability": [
      {
        "additionalLinks": [
          {
            "location": {
              "predictions": [],
              "search": "Barcelona, Barcelona, Spain",
              "selectedPlace": {
                "address": "Barcelona, Barcelona, Spain",
                "bounds": {
                  "_sdkType": "LatLngBounds",
                  "ne": {
                    "lat": 41.467943,
                    "lng": 2.2414919
                  },
                  "sw": {
                    "lat": 41.2986947,
                    "lng": 2.052429
                  }
                },
                "origin": {
                  "lat": 41.382894,
                  "lng": 2.177432
                }
              }
            },
            "pickupTime": "03:00"
          }
        ],
        "day": "Monday",
        "dayOfWeek": "mon",
        "endTime": "05:00",
        "instructors": [
          {
            "key": "Ankit sharma",
            "label": "Ankit sharma",
            "value": "Ankit sharma"
          }
        ],
        "location": {
          "predictions": [],
          "search": "Madrid, Madrid, Spain",
          "selectedPlace": {
            "address": "Madrid, Madrid, Spain",
            "bounds": {
              "_sdkType": "LatLngBounds",
              "ne": {
                "lat": 40.643282,
                "lng": -3.518126
              },
              "sw": {
                "lat": 40.312064,
                "lng": -3.888956
              }
            },
            "origin": {
              "lat": 40.416705,
              "lng": -3.703583
            }
          }
        },
        "locationName": "Madrid ",
        "seats": 45,
        "startTime": "04:00",
        "weekSame": "Wednesday"
      },
      {
        "additionalLinks": [
          {
            "location": {
              "predictions": [],
              "search": "Barcelona, Barcelona, Spain",
              "selectedPlace": {
                "address": "Barcelona, Barcelona, Spain",
                "bounds": {
                  "_sdkType": "LatLngBounds",
                  "ne": {
                    "lat": 41.467943,
                    "lng": 2.2414919
                  },
                  "sw": {
                    "lat": 41.2986947,
                    "lng": 2.052429
                  }
                },
                "origin": {
                  "lat": 41.382894,
                  "lng": 2.177432
                }
              }
            },
            "pickupTime": "03:00"
          }
        ],
        "day": "Thursday",
        "dayOfWeek": "thu",
        "endTime": "05:00",
        "instructors": [
          {
            "key": "Ankit sharma",
            "label": "Ankit sharma",
            "value": "Ankit sharma"
          }
        ],
        "location": {
          "predictions": [],
          "search": "Madrid, Madrid, Spain",
          "selectedPlace": {
            "address": "Madrid, Madrid, Spain",
            "bounds": {
              "_sdkType": "LatLngBounds",
              "ne": {
                "lat": 40.643282,
                "lng": -3.518126
              },
              "sw": {
                "lat": 40.312064,
                "lng": -3.888956
              }
            },
            "origin": {
              "lat": 40.416705,
              "lng": -3.703583
            }
          }
        },
        "locationName": "Madrid ",
        "seats": 45,
        "startTime": "04:00",
        "weekSame": "Monday"
      }
    ],



    "location": {
      "address": null
    },

    "seats": 45,
    "subscriptionPrices": [
      {
        "currency": "usd",
        "interval": "month",
        "interval_count": 1,
        "unit_amount": 5000
      }
    ],// subscriprtion array which we create subscription in stripe  
    "subscriptionCreated": true,
    "productSubscriptionId": "prod_PH8xVr4oxQAuV9", // subscription product id 
    "paymentFormat": "MONTH", // subscription chareges 
    "priceSubscriptionIds": [
      {
        "active": true,
        "billing_scheme": "per_unit",
        "created": 1703835574,
        "currency": "usd",
        "custom_unit_amount": null,
        "id": "price_1OSafKLjbvDHVQQ4MhKMnbJ6",
        "livemode": false,
        "lookup_key": null,
        "metadata": {},
        "nickname": null,
        "object": "price",
        "product": "prod_PH8xVr4oxQAuV9",
        "recurring": {
          "aggregate_usage": null,
          "interval": "month",
          "interval_count": 1,
          "trial_period_days": null,
          "usage_type": "licensed"
        },
        "tax_behavior": "unspecified",
        "tiers_mode": null,
        "transform_quantity": null,
        "type": "recurring",
        "unit_amount": 5000,
        "unit_amount_decimal": "5000"
      }
    ], // subscription prices 

    // EditListingDetailsDescription
    "shortDescription": "Descripción de clase",
    "cancelPolicy": "Política de cancelaciónPolítica de cancelaciónPolítica de cancelación",
    "city": "Washington",
    "pickupEnabled": true,

    "sortPriority": 3, // search schema for sorting based on prority 
    "legalDocumentation": [
      {
        "key": "new",
        "label": "new",
        "text": "new"
      },
      {
        "key": "Rajat",
        "label": "Rajat",
        "text": "rajat"
      }
    ],  // shows at the time of purchase a lesson 
  }
};
// <<<<<<<<<<<< Session LESSON SCHEMA >>>>>>>>>>>>>>>>
const sessionLessonListingSchema = {
  "learningType": "SESSION", // as lesson type have two type SCHOOL(for school Profile we crete to show in search) AND LESSON(for purchase a lesson So we search as per according)  AND SESSION(for sessions )
  "lessonName": "TEST",
  "Sunday": false,
  "additionalQuestions": [
    {
      "key": "whats your Age.",
      "label": "whats your Age.",
      "question": "whats your Age.",
      "questionType": "number",
      "value": "whats your Age."
    },
    {
      "key": "whats your size of shoes.",
      "label": "whats your size of shoes.",
      "question": "whats your size of shoes.",
      "questionType": "select",
      "value": "whats your size of shoes."
    }
  ], // 

  "parentId": "65d2edc2-cb9c-43b3-a760-70784deaed57", // lesson listing Id (parent of that session) 
  "parentLessonName": "sunil lesosn",// lesson listing Name (parent of that session)
  "sessionDatePassed": 1722364200, // passed date filter to show session was in past 
  "periodicity": "multiple", // may be single as well as multiple 
  "startDate": "2024-04-26",
  "endDate": "2024-07-31",
  "openAccordion": "Tuesday",
  "dayAvailability": [
    {
      "day": "Tuesday",
      "dayOfWeek": "tue",
      "endTime": "02:00",
      "instructors": [
        {
          "key": 9,
          "label": "Ankit",
          "value": "664c7912-ac9a-48ea-aec6-3d2a9ce453dc"
        },
        {
          "key": 8,
          "label": "Rakesh",
          "value": "6645d06a-d679-4146-9ced-2ac57f43aa82"
        }
      ],
      "locationName": "Madrid",
      "seats": 23,
      "startTime": "01:00"
    }
  ],
}

