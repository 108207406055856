import { fetchCurrentUser } from '../../ducks/user.duck';
import { updatedEntities, denormalisedEntities } from '../../util/data';
import { storableError } from '../../util/errors';
import { createImageVariantConfig } from '../../util/sdkLoader';
import { LESSON_TYPE } from '../../util/types';
import { parse } from '../../util/urlHelpers';

// Pagination page size might need to be dynamic on responsive page layouts
// Current design has max 3 columns 42 is divisible by 2 and 3
// So, there's enough cards to fill all columns on full pagination pages
const RESULT_PAGE_SIZE = 100;

// ================ Action types ================ //

export const FETCH_LISTINGS_REQUEST = 'app/ManageListingsPage/FETCH_LISTINGS_REQUEST';
export const FETCH_LISTINGS_SUCCESS = 'app/ManageListingsPage/FETCH_LISTINGS_SUCCESS';
export const FETCH_LISTINGS_ERROR = 'app/ManageListingsPage/FETCH_LISTINGS_ERROR';

export const OPEN_LISTING_REQUEST = 'app/ManageListingsPage/OPEN_LISTING_REQUEST';
export const OPEN_LISTING_SUCCESS = 'app/ManageListingsPage/OPEN_LISTING_SUCCESS';
export const OPEN_LISTING_ERROR = 'app/ManageListingsPage/OPEN_LISTING_ERROR';

export const CLOSE_LISTING_REQUEST = 'app/ManageListingsPage/CLOSE_LISTING_REQUEST';
export const CLOSE_LISTING_SUCCESS = 'app/ManageListingsPage/CLOSE_LISTING_SUCCESS';
export const CLOSE_LISTING_ERROR = 'app/ManageListingsPage/CLOSE_LISTING_ERROR';


export const UPDATE_OWN_LISTING_REQUEST = 'app/ManageListingsPage/UPDATE_OWN_LISTING_REQUEST';
export const UPDATE_OWN_LISTING_SUCCESS = 'app/ManageListingsPage/UPDATE_OWN_LISTING_SUCCESS';
export const UPDATE_OWN_LISTING_ERROR = 'app/ManageListingsPage/UPDATE_OWN_LISTING_ERROR';

export const USER_TRANSACTION_REQUEST = 'app/ManageListingsPage/USER_TRANSACTION_REQUEST';
export const QUERY_LISTINGS_SESSION_REQUEST = 'app/ManageListingsPage/QUERY_LISTINGS_SESSION_REQUEST';

export const ADD_OWN_ENTITIES = 'app/ManageListingsPage/ADD_OWN_ENTITIES';

export const DRAFT_LISTING_SUCCESS = 'app/ManageListingsPage/DRAFT_LISTING_SUCCESS';
export const CLOSED_LISTING_SUCCESS = 'app/ManageListingsPage/CLOSED_LISTING_SUCCESS';

// ================ Reducer ================ //

const initialState = {
  pagination: null,
  queryParams: null,
  queryInProgress: true,
  queryListingsError: null,
  currentPageResultIds: [],
  ownEntities: {},
  openingListing: null,
  openingListingError: null,
  closingListing: null,
  closingListingError: null,
  userTransactionRequest: false,
  queryListingSessionRequest: false,
  draftListings: [],
  updateOwnListingRequest: false,
  closedListings: []
};

const resultIds = data => data.data.map(l => l.id);

const merge = (state, sdkResponse) => {
  const apiResponse = sdkResponse.data;
  return {
    ...state,
    ownEntities: updatedEntities({ ...state.ownEntities }, apiResponse),
  };
};

const updateListingAttributes = (state, listingEntity) => {
  const oldListing = state.ownEntities.ownListing[listingEntity.id.uuid];
  const updatedListing = { ...oldListing, attributes: listingEntity.attributes };
  const ownListingEntities = {
    ...state.ownEntities.ownListing,
    [listingEntity.id.uuid]: updatedListing,
  };
  return {
    ...state,
    ownEntities: { ...state.ownEntities, ownListing: ownListingEntities },
  };
};

const manageListingsPageReducer = (state = initialState, action = {}) => {
  const { type, payload } = action;
  switch (type) {
    case FETCH_LISTINGS_REQUEST:
      return {
        ...state,
        queryParams: payload.queryParams,
        queryInProgress: true,
        queryListingsError: null,
        currentPageResultIds: [],
      };
    case FETCH_LISTINGS_SUCCESS:
      return {
        ...state,
        currentPageResultIds: payload.data.data,
        pagination: payload.data.meta,
        queryInProgress: false,
      };
    case FETCH_LISTINGS_ERROR:
      // eslint-disable-next-line no-console
      console.error(payload);
      return { ...state, queryInProgress: false, queryListingsError: payload };

    case OPEN_LISTING_REQUEST:
      return {
        ...state,
        openingListing: payload.listingId,
        openingListingError: null,
      };
    case OPEN_LISTING_SUCCESS:
      return {
        ...updateListingAttributes(state, payload.data),
        openingListing: null,
      };
    case OPEN_LISTING_ERROR: {
      // eslint-disable-next-line no-console
      console.error(payload);
      return {
        ...state,
        openingListing: null,
        openingListingError: {
          listingId: state.openingListing,
          error: payload,
        },
      };
    }

    case UPDATE_OWN_LISTING_REQUEST:
      return {
        ...state,
        updateOwnListingRequest: payload
      };
    case UPDATE_OWN_LISTING_SUCCESS:
      return {
        ...state,
        updateOwnListingRequest: false,
        currentPageResultIds: state.currentPageResultIds.map((st) => st?.id?.uuid == payload?.id?.uuid ? payload : st)
      };
    case UPDATE_OWN_LISTING_ERROR: {
      // eslint-disable-next-line no-console

      return {
        ...state,
        updateOwnListingRequest: false
      };
    }

    case CLOSE_LISTING_REQUEST:
      return {
        ...state,
        closingListing: payload.listingId,
        closingListingError: null,
      };
    case CLOSE_LISTING_SUCCESS:
      return {
        ...updateListingAttributes(state, payload.data),
        closingListing: null,
      };
    case CLOSE_LISTING_ERROR: {
      // eslint-disable-next-line no-console
      console.error(payload);
      return {
        ...state,
        closingListing: null,
        closingListingError: {
          listingId: state.closingListing,
          error: payload,
        },
      };
    }

    case USER_TRANSACTION_REQUEST: {
      return {
        ...state,
        userTransactionRequest: payload
      }
    }
    case QUERY_LISTINGS_SESSION_REQUEST: {
      return {
        ...state,
        queryListingSessionRequest: payload
      }
    }

    case ADD_OWN_ENTITIES:
      return merge(state, payload);

    case DRAFT_LISTING_SUCCESS:
      return {
        ...state,
        draftListings: payload
      }
    case CLOSED_LISTING_SUCCESS:
      return {
        ...state,
        closedListings: payload
      }

    default:
      return state;
  }
};

export default manageListingsPageReducer;

// ================ Selectors ================ //

/**
 * Get the denormalised own listing entities with the given IDs
 *
 * @param {Object} state the full Redux store
 * @param {Array<UUID>} listingIds listing IDs to select from the store
 */
export const getOwnListingsById = (state, listingIds) => {
  const { ownEntities } = state.ManageListingsPage;
  const resources = listingIds.map(id => ({
    id,
    type: 'ownListing',
  }));
  const throwIfNotFound = false;
  return denormalisedEntities(ownEntities, resources, throwIfNotFound);
};

// ================ Action creators ================ //

// This works the same way as addMarketplaceEntities,
// but we don't want to mix own listings with searched listings
// (own listings data contains different info - e.g. exact location etc.)
export const addOwnEntities = sdkResponse => ({
  type: ADD_OWN_ENTITIES,
  payload: sdkResponse,
});

export const openListingRequest = listingId => ({
  type: OPEN_LISTING_REQUEST,
  payload: { listingId },
});

export const openListingSuccess = response => ({
  type: OPEN_LISTING_SUCCESS,
  payload: response.data,
});

export const openListingError = e => ({
  type: OPEN_LISTING_ERROR,
  error: true,
  payload: e,
});

export const closeListingRequest = listingId => ({
  type: CLOSE_LISTING_REQUEST,
  payload: { listingId },
});

export const closeListingSuccess = response => ({
  type: CLOSE_LISTING_SUCCESS,
  payload: response.data,
});

export const closeListingError = e => ({
  type: CLOSE_LISTING_ERROR,
  error: true,
  payload: e,
});

export const queryListingsRequest = queryParams => ({
  type: FETCH_LISTINGS_REQUEST,
  payload: { queryParams },
});

export const queryListingsSuccess = response => ({
  type: FETCH_LISTINGS_SUCCESS,
  payload: response,
});

export const queryListingsError = e => ({
  type: FETCH_LISTINGS_ERROR,
  error: true,
  payload: e,
});

export const userTransactionRequest = (data) => ({
  type: USER_TRANSACTION_REQUEST,
  payload: data
});

export const queryListingSessionRequest = (data) => ({
  type: QUERY_LISTINGS_SESSION_REQUEST,
  payload: data
});

export const draftListingsSuccess = (data) => ({
  type: DRAFT_LISTING_SUCCESS,
  payload: data
});

export const closedListingsSuccess = (data) => ({
  type: CLOSED_LISTING_SUCCESS,
  payload: data
});

export const updateOwnListingRequest = listingId => ({
  type: UPDATE_OWN_LISTING_REQUEST,
  payload: listingId,
});

export const updateOwnListingSuccess = response => ({
  type: UPDATE_OWN_LISTING_SUCCESS,
  payload: response,
});

export const updateOwnListingError = e => ({
  type: UPDATE_OWN_LISTING_ERROR,
  error: true,
});



export const getProperResponseOfListing = (response, config) => {
  const responseEntries = response.data.data.length ? updatedEntities({}, response.data, { listingFields: config?.listing?.listingFields }) : {};
  const resources = response.data.data.length ? response.data.data.map((st) => ({ id: st.id, type: "listing" })) : [];
  const responseArray = response.data.data.length ? denormalisedEntities(responseEntries, resources, false) : [];
  return responseArray;
}

export const updateOwnListings = (params) => (dispatch, getState, sdk) => {
  dispatch(updateOwnListingRequest(params.id.uuid))
  const variantPrefix = "listing-card";
  const aspectRatio = 1;
  const includeParams = {
    expand: true,
    include: ['images'],
    'fields.image': [`variants.${variantPrefix}`, `variants.${variantPrefix}-2x`],
    ...createImageVariantConfig(`${variantPrefix}`, 400, aspectRatio),
    ...createImageVariantConfig(`${variantPrefix}-2x`, 800, aspectRatio),
    'limit.images': 1,
  }

  return sdk.ownListings.update(params, includeParams)
    .then(response => {
      const images = response?.data?.included && response?.data?.included.length ? response?.data.included[0] : {};
      dispatch(updateOwnListingSuccess({ ...response.data.data, images: [images] }))
      return response;
    }).catch(E => {
      dispatch(updateOwnListingError(E))
    })
}


// Throwing error for new (loadData may need that info)
export const queryOwnListings = (queryParams, config) => (dispatch, getState, sdk) => {
  dispatch(queryListingsRequest(queryParams));

  const { perPage, ...rest } = queryParams;
  const params = { ...rest, perPage };

  return sdk.listings
    .query(params)
    .then(response => {
      const queryListing = getProperResponseOfListing(response, config)
      dispatch(queryListingsSuccess({
        "status": 200,
        "statusText": "",
        "data": {
          data: queryListing && queryListing.length ? queryListing : [],
          included: response.data.included,
          meta: response.data.meta
        }
      }
      ));
      return response;
    })
    .catch(e => {
      dispatch(queryListingsError(storableError(e)));
      throw e;
    });
};

export const closeListing = listingId => (dispatch, getState, sdk) => {
  // dispatch(closeListingRequest(listingId));

  return sdk.ownListings
    .close({ id: listingId }, { expand: true })
    .then(response => {
      // dispatch(closeListingSuccess(response));
      return response;
    })
    .catch(e => {
      // dispatch(closeListingError(storableError(e)));
      return e;
    });
};

export const openListing = listingId => (dispatch, getState, sdk) => {
  // dispatch(openListingRequest(listingId));

  return sdk.ownListings
    .open({ id: listingId }, { expand: true })
    .then(response => {
      // dispatch(openListingSuccess(response));
      return response;
    })
    .catch(e => {
      // dispatch(openListingError(storableError(e)));
      return e
    });
};

export const queryDraftListings = (config, params) => (dispatch, getState, sdk) => {
  return sdk.ownListings.query({ ...params })
    .then(res => {
      const closedListings = res.data.data.filter((st) => st.attributes.state == "closed");
      const response = res.data.data.filter((st) => st.attributes.state == "draft");
      dispatch(draftListingsSuccess(response));
      dispatch(closedListingsSuccess(closedListings));
      // dispatch()
    });
};

export const discardDraftListings = (listingId, prevDraftListings) => (dispatch, getState, sdk) => {
  return sdk.ownListings.discardDraft({
    id: listingId
  }, {
    expand: false
  }).then(res => {
    const newDraftListings = prevDraftListings.filter((st) => st.id.uuid != listingId.uuid);
    dispatch(draftListingsSuccess(newDraftListings))
  });
};

export const loadData = (params, search, config) => (dispatch, getState) => {
  const queryParams = parse(search);
  const page = queryParams.page || 1;

  const {
    aspectWidth = 1,
    aspectHeight = 1,
    variantPrefix = 'listing-card',
  } = config.layout.listingImage;
  const aspectRatio = aspectHeight / aspectWidth;

  return Promise.all([dispatch(fetchCurrentUser())])
    .then(async response => {
      const currentUser = getState().user.currentUser;
      const authorId = currentUser.id.uuid;
      await dispatch(queryDraftListings(config, {
        page,
        perPage: RESULT_PAGE_SIZE,
        include: ['images'],
        'fields.image': [`variants.${variantPrefix}`, `variants.${variantPrefix}-2x`],
        ...createImageVariantConfig(`${variantPrefix}`, 400, aspectRatio),
        ...createImageVariantConfig(`${variantPrefix}-2x`, 800, aspectRatio),
        'limit.images': 1,
      }
      ));

      dispatch(queryOwnListings({
        ...queryParams,
        page,
        perPage: RESULT_PAGE_SIZE,
        pub_learningType: LESSON_TYPE,
        authorId: authorId,
        include: ['images'],
        'fields.image': [`variants.${variantPrefix}`, `variants.${variantPrefix}-2x`],
        ...createImageVariantConfig(`${variantPrefix}`, 400, aspectRatio),
        ...createImageVariantConfig(`${variantPrefix}-2x`, 800, aspectRatio),
        'limit.images': 1,
      }));

      return response;
    })
    .catch(e => {
      throw e;
    });
}

export const getProperResponseOfTransaction = (response, config) => {
  const responseEntries = response.data.data.length ? updatedEntities({}, response.data, { listingFields: config?.listing?.listingFields }) : {};
  const resources = response.data.data.length ? response.data.data.map((st) => ({ id: st.id, type: "transaction" })) : [];
  const responseArray = response.data.data.length ? denormalisedEntities(responseEntries, resources, false) : [];
  return responseArray;
}

export const getFilteredTransactions = (transactions = []) => {
  let providerDetails = [];
  for (let i = 0; i < transactions.length; i++) {
    const { attributes, customer, listing } = transactions[i] || {};
    const { userPackages = {} } = attributes?.metadata || {};
    const indexValue = providerDetails.length ? providerDetails.findIndex((st) => st.id.uuid == customer?.id?.uuid) : -1;
    if (indexValue >= 0) {
      providerDetails = providerDetails.map((st) => ({ ...st, userPackages: [...st.userPackages, userPackages] }))
    } else {
      providerDetails = [...providerDetails, { ...customer, userPackages: [userPackages] }]
    }
  }
  return providerDetails;
}


// id must be in uuid 
export const queryUserTransactions = (id, config) => async (dispatch, getState, sdk) => {
  dispatch(userTransactionRequest(id.uuid))
  return sdk.transactions.query({
    processNames: "default-purchase,session-course",
    only: "sale",
    listingId: id,
    lastTransitions: ["transition/free-lesson", "transition/cash-lesson", "transition/cash-lesson-accept", 'transition/lesson-completed-customer', 'transition/review-1-by-customer', 'transition/confirm-payment', "transition/auto-complete", "transition/expire-review-period", "transition/expire-customer-review-period"],
    include: [
      'customer',
      'customer.profileImage',
      'provider',
      'provider.profileImage',
      'listing',
      'booking',
      'reviews',
      'reviews.author',
      'reviews.subject',
    ],
  })
    .then(response => {
      dispatch(userTransactionRequest(false));
      const transactionResponse = getProperResponseOfTransaction(response, config);
      const getTransactions = transactionResponse && transactionResponse.length ? getFilteredTransactions(transactionResponse) : []
      return getTransactions;
    }).catch((e) => {
      dispatch(userTransactionRequest(false));
      return [];
    })
};

// id must be string for query 
export const queryListings = (id) => async (dispatch, getState, sdk) => {
  dispatch(queryListingSessionRequest(id));
  return sdk.listings
    .query({ pub_parentId: id })
    .then(response => {
      dispatch(queryListingSessionRequest(false));
      return response.data.data;
    })
    .catch(e => {
      dispatch(queryListingSessionRequest(false));
      return [];
    });
};

