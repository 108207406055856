import React from 'react';
import Button from '../Button/Button';
import IconProfileCard from '../IconProfileCard/IconProfileCard';

import css from './FreeLessonCard.module.css';
import { useIntl } from 'react-intl';

const FreeLessonCard = props => {
  const {
    currentListing,
    currentUser,
    fetchFreeLessonInProgreess,
    freeLessons,
    fetchFreeLessonError,
    handleFreeLessonSubmit,
    onCloseFreeLessonModal,
    isSchool,
    inProgress,
    initiateFreeLessonError
  } = props;

  // {
  //     "currentUserId": "65267822-5a0e-4ea6-a369-445029c34365",
  //     "email": "rakesh1@yopmail.com",
  //     "url": "http://localhost:3000/l/phy/654467a5-5e58-492e-83d1-f2c59f846286",
  //     "subject": "3 Free Lessons of Phy",
  //     "name": "Rakesh",
  //     "quantity": 3,
  //     "lessonId": "654467a5-5e58-492e-83d1-f2c59f846286",
  //     "isEmailSend": false,
  //     "isLessonUsed": false,
  //     "utm": "bcdd2bdc-d894-4b59-92f7-de10a7600204",
  //     "createdAt": "2024-11-08T13:54:41.195Z",
  //     "updatedAt": "2024-11-08T13:54:41.195Z",
  //     "publishedAt": "2024-11-08T13:54:41.192Z",
  //     "id": 2
  // }
  const intl= useIntl();
  const { lessonId, email: fEmail, currentUserId, isLessonUsed } = freeLessons || {};
  const { id } = currentListing || {};
  const { email = '' } = (currentUser && currentUser.id && currentUser.attributes) || {};
  const freeLessonValid = (!isSchool) && lessonId && id && (lessonId == id.uuid) && (email == fEmail);
  const isValidData = freeLessonValid && (!isLessonUsed);
  const isAlreadyUsed = freeLessonValid && (isLessonUsed);

  const isProvider = currentUserId && id && id.uuid == currentUserId;

  const title = currentListing.attributes && currentListing.attributes.title;

  const listingPageButton = (text = "") => (
    <Button onClick={(e) => {
      e.preventDefault();
      onCloseFreeLessonModal()
    }}
    >
      {text}
    </Button>
  );
  
  return <div> 
    <div className={css.crossButton} onClick={(e)=>{
        e.preventDefault();
        onCloseFreeLessonModal()
    }}>
    <IconProfileCard type="cross"/>
    </div>
    {fetchFreeLessonInProgreess
    ? <div>{intl.formatMessage({ id: 'FreeLessonCard.loadingLabel' })}</div>

    : (
      <div className={css.lessonQueryModal}>

        {isValidData
          ?
          <div className={css.usedLesson}>
            <h3>{intl.formatMessage({ id: 'FreeLessonCard.getFreeLessonsLabel' })}</h3>   
            <p>{intl.formatMessage({ id: 'FreeLessonCard.youHaveRecievedLabel' })} { " " + freeLessons?.quantity}
            {/* {intl.formatMessage({ id: 'FreeLessonCard.quantityOfLabel' })}  */}
            {title} 
            </p>
            <Button 
            className={css.getLessonButton} 
            onClick={(e) => {
              e.preventDefault();
              handleFreeLessonSubmit({ quantity: freeLessons.quantity, isFreeLesson: freeLessons });
            }}
              inProgress={inProgress}
            >
              {intl.formatMessage({ id: 'FreeLessonCard.getFreeLessonsLabel' })}
            </Button>
            {/* {listingPageButton("Doesn't need")} */}
          </div>
          : isAlreadyUsed
            ?
            <div className={css.usedLesson}>
              <h3>{intl.formatMessage({ id: 'FreeLessonCard.buyLessonsLabel' })}</h3>
              <p>{intl.formatMessage({ id: 'FreeLessonCard.youHaveUsedFreeLessonsLabel' })}</p>
              {listingPageButton("Buy Lesson")}
            </div>
            : isProvider
              ?
              <div className={css.usedLesson}>
                <h3>{intl.formatMessage({ id: 'FreeLessonCard.freeLessonsLabel' })}</h3>
                <p>{intl.formatMessage({ id: 'FreeLessonCard.linkSharedToGiveFreeLessonToEmailLabel' })}{freeLessons.email}{intl.formatMessage({ id: 'FreeLessonCard.quantityIsLabel' })} {freeLessons.quantity} </p>
                {listingPageButton("Close Modal")}
              </div>
              : isSchool
                ?
                <div className={css.usedLesson}>
                  <h3>{intl.formatMessage({ id: 'FreeLessonCard.getFreeLessonsLabel' })}</h3>
                  <p>{intl.formatMessage({ id: 'FreeLessonCard.schoolNotGetFreeLessonLabel' })} </p>
                  {listingPageButton("Close")}
                </div>
                :
                <div className={css.usedLesson}>
                  <h3>{intl.formatMessage({ id: 'FreeLessonCard.buyLessonHereLabel' })}</h3>
                  {listingPageButton("Buy Lesson")}
                </div>
        }
        {initiateFreeLessonError ? <div style={{ color: "red" }}>{intl.formatMessage({ id: 'FreeLessonCard.somethingWentWrongLabel' })} </div> : null}
      </div>
    )
    }
      </div>
};

export default FreeLessonCard;