import { storableError } from '../../util/errors';
import { parse } from '../../util/urlHelpers';
import { getUserRole } from '../../util/helper';
import { CHAT_BY_SCHOOL, CHAT_BY_USER, CHAT_TYPE_INDIVIDUAL, SCHOOL_TYPE } from '../../util/types';
import { fetchCurrentUser } from '../../ducks/user.duck';
import { onGetStrapiChatList, onUpdateApiCollecton } from '../../util/api';
import { STRAPI_CHAT_LIST_URL } from '../../config/configStrapiUrl';

const RESULT_PAGE_SIZE = 100;

// ================ Action types ================ //

export const SEND_MESSAGE_REQUEST = 'app/ChatPage/SEND_MESSAGE_REQUEST';
export const SEND_MESSAGE_NOTIFICATION_REQUEST = 'app/ChatPage/SEND_MESSAGE_NOTIFICATION_REQUEST';

export const FETCH_CHAT_LIST_REQUEST = 'app/ChatPage/FETCH_CHAT_LIST_REQUEST';
export const FETCH_CHAT_LIST_SUCCESS = 'app/ChatPage/FETCH_CHAT_LIST_SUCCESS';
export const FETCH_CHAT_LIST_ERROR = 'app/ChatPage/FETCH_CHAT_LIST_ERROR';

export const FETCH_CHAT_NOTIFICATION_REQUEST = 'app/ChatPage/FETCH_CHAT_NOTIFICATION_REQUEST';
export const FETCH_CHAT_NOTIFICATION_SUCCESS = 'app/ChatPage/FETCH_CHAT_NOTIFICATION_SUCCESS';
export const FETCH_CHAT_NOTIFICATION_ERROR = 'app/ChatPage/FETCH_CHAT_NOTIFICATION_ERROR';

export const DELETE_ALL_CHATS_REQUEST = 'app/ChatPage/DELETE_ALL_CHATS_REQUEST';
export const DELETE_ALL_CHATS_SUCCESS = 'app/ChatPage/DELETE_ALL_CHATS_SUCCESS';
export const DELETE_ALL_CHATS_ERROR = 'app/ChatPage/DELETE_ALL_CHATS_ERROR';

export const CHAT_PAGE_LOADER = 'app/ChatPage/CHAT_PAGE_LOADER';
export const CHAT_PAGE_PARAMS = 'app/ChatPage/CHAT_PAGE_PARAMS';

// ================ Reducer ================ //

const initialState = {
    chatPageLoader: false,
    pageParams: null,
    paginationChat: null,
    chatListRequest: false,
    chatListNotificationRequest: false,
    chatNotificationLists: [],
    chatNotificationListError: false,
    chatLists: [],
    chatListError: false,
    paginationChatList: false,
    sendMessageRequest: false,
    sendMessageNotificationRequest: false,
    deleteAllChatsLoader: false,
    deleteAllChatsError: null
};

export default function ChatPageReducer(state = initialState, action = {}) {
    const { type, payload } = action;
    switch (type) {

        case CHAT_PAGE_LOADER:
            return { ...state, chatPageLoader: payload };
        case CHAT_PAGE_PARAMS:
            return { ...state, pageParams: payload };

        case FETCH_CHAT_LIST_REQUEST:
            return { ...state, chatListRequest: true, chatLists: [], chatListError: false };
        case FETCH_CHAT_LIST_SUCCESS:
            return { ...state, chatListRequest: false, chatLists: payload.data, chatListError: false, paginationChatList: payload.pagination };
        case FETCH_CHAT_LIST_ERROR:
            return { ...state, chatListRequest: false, chatLists: [], chatListError: payload };
        case FETCH_CHAT_NOTIFICATION_REQUEST:
            return { ...state, chatListNotificationRequest: true, chatNotificationLists: [], chatNotificationListError: false };
        case FETCH_CHAT_NOTIFICATION_SUCCESS:
            return { ...state, chatListNotificationRequest: false, chatNotificationLists: payload.data, chatNotificationListError: false, paginationChatList: payload.pagination };
        case FETCH_CHAT_NOTIFICATION_ERROR:
            return { ...state, chatListNotificationRequest: false, chatNotificationLists: [], chatNotificationListError: payload };

        case SEND_MESSAGE_REQUEST:
            return { ...state, sendMessageRequest: payload };

        case SEND_MESSAGE_NOTIFICATION_REQUEST:
            return { ...state, sendMessageNotificationRequest: payload };

        case DELETE_ALL_CHATS_REQUEST:
            return { ...state, deleteAllChatsLoader: payload, deleteAllChatsError: false };
        case DELETE_ALL_CHATS_SUCCESS:
            return { ...state, deleteAllChatsLoader: false, chatLists: state.chatLists.filter((st) => st.id != payload), deleteAllChatsError: false };
        case DELETE_ALL_CHATS_ERROR:
            return { ...state, deleteAllChatsLoader: false, deleteAllChatsError: payload };

        default:
            return state;
    }
}

// ================ Action creators ================ //

export const onSendChatMessageRequest = (data) => ({
    type: SEND_MESSAGE_REQUEST,
    payload: data
})
export const onSendChatMessageNotificationRequest = (data) => ({
    type: SEND_MESSAGE_NOTIFICATION_REQUEST,
    payload: data
})

export const onfetchChatListRequest = () => ({
    type: FETCH_CHAT_LIST_REQUEST,
});
export const onfetchChatListSuccess = (data) => ({
    type: FETCH_CHAT_LIST_SUCCESS,
    payload: data,
});
export const onfetchChatListError = e => ({
    type: FETCH_CHAT_LIST_ERROR,
    payload: e,
});

export const deleteAllChatsRequest = (id) => ({
    type: DELETE_ALL_CHATS_REQUEST,
    payload: id
});

export const deleteAllChatsSuccess = (id) => ({
    type: DELETE_ALL_CHATS_SUCCESS,
    payload: id,
});

export const deleteAllChatsError = e => ({
    type: DELETE_ALL_CHATS_ERROR,
    payload: e,
});


export const pageLoader = (data) => ({
    type: CHAT_PAGE_LOADER,
    payload: data
});

export const chatPageParams = (data) => ({
    type: CHAT_PAGE_PARAMS,
    payload: data
});

// ================ Thunks ================ //




export const onfetchChatList = (data) => async (dispatch, getState, sdk) => {
    dispatch(onfetchChatListRequest());
    const { page, pageSize, senderId } = data;

    const searchFilter = `pagination[page]=${page}&pagination[pageSize]=${pageSize}&filters[users][$contains]=${senderId}&sort[0]=updatedAt:asc&filters[isDeleted][$eq]=false`;

    return onGetStrapiChatList({ searchFilter })
        .then(async (response) => {
            dispatch(onfetchChatListSuccess(response));
            return response;
        })
        .catch(e => {
            dispatch(onfetchChatListError(storableError(e)))
            return storableError(e);
        });
};

export const deleteAllChats = (params) => (dispatch, getState, sdk) => {
    const { id } = params;
    dispatch(deleteAllChatsRequest(id));
    return onUpdateApiCollecton({ id, API_TYPE: STRAPI_CHAT_LIST_URL, data: { ...params, isDeletedWholeChat: true } })
        .then(response => {
            dispatch(deleteAllChatsSuccess(id));
            return response;
        })
        .catch(e => {
            dispatch(deleteAllChatsError(storableError(e)))
            return storableError(e);
        });
};

export const loadData = (params, search, config) => (dispatch, getState, sdk) => {
    try {
        dispatch(pageLoader(true));
        const queryParams = parse(search);
        const page = queryParams.page || 1;
        const pageParams = {
            page,
            pageSize: RESULT_PAGE_SIZE,
        }

        return Promise.all([dispatch(fetchCurrentUser())])
            .then(async () => {
                const currentUser = getState().user.currentUser;
                const senderId = currentUser.id.uuid;
                const chatIdMaybe = params && params.id ? { chatId: params.id } : {}
                const chatJson = { ...chatIdMaybe, senderId, ...pageParams };
                dispatch(chatPageParams(chatJson));
                Promise.all([
                    dispatch(onfetchChatList(chatJson))])
            })
            .then(() => {
                dispatch(pageLoader(false));
            })
            .catch((e) => {
                dispatch(pageLoader(false));
            })
    } catch (e) {
        dispatch(pageLoader(false));
    }
};
